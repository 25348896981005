import React, { useRef, useEffect, useState } from "react";
import logo from './logo.png';
import './App.scss';
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Page404 from './pages/404';
import RecognitionsPage from "./pages/recognitions";
import Login from './components/login/login';
import RecognitionForm from './components/recognitionForm/RecognitionForm';
import useToken from "./hooks/useToken";
import useUsername from "./hooks/useUsername";
import RecognitionsList from "./components/recognitionsList/RecognitionsList";
import RecognitionsListPage from "./pages/recognitionsListPage";

const usersWithAccessToRecognitions = ['eaf530', 'dcc566'];

function App() {
  const navigate = useNavigate();
  const { token, setToken, isTokenValid } = useToken();
  const { setUsername, username } = useUsername();
  
  useEffect(() => {
    window.addEventListener('focus', () => {
      if (!isTokenValid()){
        setToken(null);
        setUsername(null);
        navigate('/', { replace: true });
      }
    })
  }, []);

  let routes = <Routes>
  <Route path="/" element={<Layout setToken={setToken} setUsername={setUsername} username={username} />}>
      <Route index element={<RecognitionsPage token={token} username={username} />} />
      { usersWithAccessToRecognitions.includes(username) ? 
        <Route path="recognitions" element={<RecognitionsListPage token={token} username={username} />} />
        : ''}
      {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
      <Route path="*" element={<Page404 />} />
  </Route>
</Routes>;

  if (!token) {
    routes = <div>
        <Container className='pt-2 mt-5 w-50 mx-auto'>
        <Routes>
          <Route path="*" element={<Login setToken={setToken} saveUsername={setUsername} />} />
        </Routes>
      </Container>
      <Footer />
    </div>
  }

  return (
    <div className="app">
      <header className="header">
        <img src={logo} className="app-logo" alt="logo" />
        <h1>Fybr Labs Recognition Portal</h1>
      </header>
      {routes}
    </div>
  );
}

function Layout({ setToken, setUsername, username }) {
  const navigate = useNavigate();

  const logout = () => {
    setToken(null);
    setUsername(null);
    navigate('/', { replace: true });
  };

  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}

      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Recognize</Nav.Link>
              </LinkContainer>
              {usersWithAccessToRecognitions.includes(username) ?
                <LinkContainer to="/recognitions">
                  <Nav.Link>Recognitions</Nav.Link>
                </LinkContainer>
                : ''
              }
            </Nav>
            <Nav>
              <Nav.Link onClick={ev => {ev.preventDefault();}} disabled>Welcome {username}</Nav.Link>
              <Nav.Link onClick={ev => {ev.preventDefault(); logout();}}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='pt-2 mt-5'>
          <Outlet />
      </Container>
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <footer>
    <p>Environment: {process.env.NODE_ENV}</p>
    <p>Version: {process.env.REACT_APP_VERSION}</p>
  </footer>
  );
}

export default App;
