import React, { useRef, useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import RecognitionForm from "../components/recognitionForm/RecognitionForm";
import RecognitionsList from "../components/recognitionsList/RecognitionsList";
import Card from "../components/card/Card";

export default function RecognitionsPage({token, username}) {
  return (
    <div className="recognitions-page">
      <Row>
        <Col>
          <RecognitionForm token={token} username={username} />
        </Col>
        <Col xs={4}>
          <Card>
            <header>Frontier Recognition Programs</header>
            <section>
            <ul>
              <li><a href="https://frontiercorp1.sharepoint.com/sites/MyFrontier/SitePages/Changemakers.aspx" target="_blank">Nominate a Changemaker</a></li>
              <li><a href="https://frontierguide.performnet.com/" target="_blank">Share a Cause for Applause</a></li>
              <li><a href="https://web.yammer.com/main/org/ftr.com/feed" target="_blank">Celebrate on Yammer</a></li>
            </ul>
          </section>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
