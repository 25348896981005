import React, { useRef, useEffect, useState } from "react";
import Card from "../card/Card";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { exportToCsv } from "./exportUtils";

const apiEndpoint = 'https://223a1feww1.execute-api.us-west-2.amazonaws.com/Prod';

export default function RecognitionsList({token, month}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getItems = async () => {
    setLoading(true);
    let response = await axios.get(`${apiEndpoint}/${month.year}/${month.month}`, {headers: {'auth-token': token}});
    let list = response.data;
    list = list.sort((a, b) => {
      if (a.year - b.year !== 0) {
        return b.year - a.year;
      } else {
        return b.month - a.month;
      }
    })
    setItems(list);
    setLoading(false);
  }
  useEffect(() => {
    getItems();
  }, [month]);

  function generateList() {
    let list = items.map(el => {
      return {
        employee: el.recognizedEmployee.firstName + ' ' + el.recognizedEmployee.lastName,
        submittedBy: el.submittedByEmployee.firstName + ' ' + el.submittedByEmployee.lastName,
        category: el.category,
        year: el.year,
        month: el.month,
        comments: el.comments,
        timestamp: el.timestamp
      }
    });
    return list;
  }
  function renderList(items) {
    return items.map((el) => {
      let d = new Date(el.timestamp);
      return <tr>
        <td>{d.toLocaleDateString()}</td>
        <td>{el.employee}</td>
        <td>{el.submittedBy}</td>
        <td>{el.category}</td>
        <td>{el.comments}</td>
      </tr>
    });
  }
  function renderTable(headers, items) {

    if (loading) {
      return <h3>Loading...</h3>;
    } else if (!items.length) {
      return <p>No items found</p>;
    }

    return <table>
    <thead>
      <tr>
        {headers.map((it) => {
          return <td>{it}</td>
        })}
      </tr>
    </thead>
    <tbody>
      {renderList(items)}
    </tbody>
  </table>;
  }

  const itemsList = generateList();
  const headers = ['Time', 'Employee', 'Submitted By', 'Category', 'Comments'];
  //let gridElement = <DataGrid columns={columns} rows={itemsList} className="rdg-dark" />;

  return (
    <div>
      <Card>
        <header>
          Recognitions
        </header>
        <section>
          <div className="text-right">
            <Button onClick={() => {exportToCsv(headers, itemsList, `fybrlabs-recognitions-${month.year}-${month.month}`)}} variant="secondary">Export to CSV</Button>
          </div>
          {renderTable(headers, itemsList)}
        </section>
      </Card>
    </div>
  );
}
