import { useState } from 'react';

const usernameKey = 'username';

export default function useUsername() {
  const [username, setUsername] = useState(getUsername());
  
  function getUsername () {
    return localStorage.getItem(usernameKey);
  }

  const saveUsername = uid => {
    if (!uid) {
      localStorage.removeItem(usernameKey);
      setUsername(uid);
      return;
    } else {
      localStorage.setItem(usernameKey, uid);
      setUsername(uid);
    }
  };

  return {
    setUsername: saveUsername,
    username: username
  }
}
