import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Alert } from "react-bootstrap";
import axios from "axios";
import Card from '../card/Card'
import { Typeahead } from "react-bootstrap-typeahead";
import Select, { components } from "react-select";

const apiEndpoint = 'https://223a1feww1.execute-api.us-west-2.amazonaws.com/Prod/';
//const apiEndpoint = 'http://localhost:3000/';

export default function RecognitionForm({token, username}) {
  const [employeesByCorpId, setEmployeesByCorpId] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [employee, setEmployee] = useState('');
  const [category, setCategory] = useState('Technical excellence');
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="left">{props.isSelected ? "✔" : ""}</div>
        <div className="right">
          <strong className="title">{props.data.label}</strong>
          <div>{props.data.description}</div>
        </div>
      </components.Option>
    );
  };

  const categories = [
    {
      value: "Technical excellence",
      label: "Technical excellence",
      description: "Awarded for an individual who provided a great technical solution to a problem or made a significant contribution to advancing Frontier's technical capabilities."
    },
    {
      value: "Technical leadership",
      label: "Technical leadership",
      description: "Awarded to an individual for leading their team, or cross-functional team project to develop a unique or impactful technical solution."
    },
    {
      value: "Getting things done",
      label: "Getting things done",
      description: "Awarded to an individual for working through a complex problem to find a more efficient or technically superior solution (working smarter, not harder)."
    }
  ];
  const categoriesStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      //console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#00A3BE" : "",
  
        color: isFocused ? "#F9FAFC" : "#191D2F",
        display: "flex",
        paddingLeft: 0,
  
        "& .left": {
          display: "flex",
          justifyContent: "center",
          width: 60,
          marginTop: 3
        },
        "& .right": {
          width: "100%"
        },
  
        "& .right > .title": {
          display: "block",
          margin: "5px 0"
        }
      };
    }
  };

  const getEmployees = async () => {
    let r = await axios.get(apiEndpoint + '/employees', { headers: { 'auth-token': token, 'Content-Type': 'application/json'}});
    let employeesByCorpId = {};
    let list = r.data.map((item) => {
      employeesByCorpId[item.corpId] = item;
      return {
        id: item.id,
        label: item.firstName + ' ' + item.lastName
      };
    });
    list.sort((a, b) => {
      const aLabel = a.label.toLowerCase();
      const bLabel = b.label.toLowerCase();
      if (aLabel < bLabel)
        return -1;
      if (aLabel > bLabel)
        return 1;
      return 0;
    })
    setEmployeesByCorpId(employeesByCorpId);
    setEmployeesList(list);
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const clearForm = async () => {
    setEmployee('');
    setComments('');
    setCategory(categories[0].value);
  }

  const handleSubmission = async (ev) => {
    ev.preventDefault();
    let r;

    try {
      setLoading(true);
      let employeeObject = employeesByCorpId[employee[0].id];
      r = await axios.put(apiEndpoint, {employee: employee[0].id, category, comments, submittedBy: username, supervisorCorpId: employeeObject.supervisorCorpId}, {
        headers: {
          'auth-token': token,
          'Content-Type': 'application/json'
      }});
      setLoading(false);
      clearForm();
      setResponse(<Alert key='submission-response' variant='success' className="mt-4" dismissible onClose={() => setResponse('')}>
        Your submission was saved successfully.
      </Alert>);
    } catch(e) {
      let error = 'Internal Server Error.';
      if (e.response && e.response.data) {
        console.log(e.response.data);
        error = e.response.data.message;
      } else {
        console.log(e);
      }
      setLoading(false);
      setResponse(<Alert key='submission-response' variant='danger' className="mt-4" dismissible>
        There was an error with your submission: {error}
      </Alert>);
    }
  }

  const renderLoading = () => {
    if (loading) {
      return <div>
      <h2>Submission in progress ...</h2>
    </div>;
    }
  }

  const renderForm = () => {
    if (!loading) {
      return <Form onSubmit={handleSubmission}>
      <Row className="mb-3">
        <Form.Group className="text employee">
          <Form.Label>Select the employee to recognize</Form.Label>
          <Typeahead id="employee" onChange={(selected) => {
              setEmployee(selected);
            }} options={employeesList}
            selected={employee} required />
        </Form.Group>
      </Row>
      <Row className="mb-3">
      <Form.Group className="select category">
          <Form.Label>Category</Form.Label>
          <Select
            defaultValue={categories[0]}
            label="Single select"
            onChange={selectedCategory => {
              console.log(`Category selected: ${JSON.stringify(selectedCategory)}`);
              setCategory(selectedCategory.value);
            }}
            options={categories}
            styles={categoriesStyles}
            components={{
              Option
            }}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group className="textarea comments">
          <Form.Label>Comments</Form.Label>
          <Form.Control as="textarea" rows={5} onChange={e => setComments(e.target.value)} required />
        </Form.Group>
      </Row>
      <Row>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <Button variant="secondary" type="reset" onClick={e => {clearForm();}}>
          Cancel
        </Button>
      </Row>
    </Form>
    }
  }

  const renderResponse = () => {
    return <Row>{response}</Row>
  }

  return(
    <Card>
      <header>Recognition</header>
      <section>
        {renderResponse()}
        {renderForm()}
        {renderLoading()}
      </section>
    </Card>
  )
}
