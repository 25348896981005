import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Alert } from "react-bootstrap";
import axios from "axios";
import Card from '../card/Card'
import { Typeahead } from "react-bootstrap-typeahead";
//import employees from "./employees";

const apiEndpoint = 'https://223a1feww1.execute-api.us-west-2.amazonaws.com/Prod/';
//const apiEndpoint = 'http://localhost:3000/';

export default function RecognitionListForm({token, username, month, setMonth}) {
  const [employeesByCorpId, setEmployeesByCorpId] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [employee, setEmployee] = useState('');
  const [category, setCategory] = useState('');
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [newMonth, setNewMonth] = useState(month.month);
  const [newYear, setNewYear] = useState(month.year);

  

  const clearForm = async () => {
    setNewMonth(month.month);
    setNewYear(month.year);
  }

  const handleSubmission = async (ev) => {
    ev.preventDefault();
    setMonth({
      month: newMonth,
      year: newYear
    });
  }

  let initialYear = 2023;
  let now = new Date();
  let currentYear = now.getFullYear();
  let years = [];
  for (let i = currentYear; i >= initialYear; i--) {
    years.push(<option value={i} key={'year-' + i} >{i}</option>);
  }
  let months = [];
  let lastMonth = 12; // January
  if (newYear === currentYear) {
    lastMonth = now.getMonth();
  }
  for (let i = 0; i <= lastMonth; i++) {
    months.push(<option value={i + 1} key={'month-' + i}>{i + 1}</option>);
  }
  return (
    <Card>
      <Form onSubmit={handleSubmission}>
        <Row className="mb-3">
          <Col xs={3}>
            <Form.Group className="select year">
              <Form.Label>Year</Form.Label>
              <Form.Select value={newYear} required onChange={e => {
                  setNewYear(e.target.value);
                }}>
                  {years}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group className="select month">
              <Form.Label>Month</Form.Label>
              <Form.Select value={newMonth} required onChange={e => {
                  setNewMonth(e.target.value);
                }}>
                  {months}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Button variant="primary" type="submit" disabled={month.month === newMonth && month.year === newYear } >
            Submit
          </Button>
        </Row>
      </Form>
    </Card>
  );
}
