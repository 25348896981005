import React, { useRef, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Alert } from "react-bootstrap";
import axios from "axios";
import Card from '../card/Card'

const apiEndpoint = 'https://223a1feww1.execute-api.us-west-2.amazonaws.com/Prod/login';
//const apiEndpoint = 'http://localhost:3000/login';

export default function Login({ setToken, saveUsername }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmission = async (ev) => {
    ev.preventDefault();
    let r;

    try {
      r = await axios.post(apiEndpoint, {username, password});
      setToken(r.data.userToken);
      saveUsername(r.data.username);
    } catch(e) {
      let errorMessage;
      if (e.response && e.response.data) {
        console.log(e.response.data);
        errorMessage = e.response.data.message;
      } else {
        console.log(e);
        errorMessage = 'Failed to login.';
      }
      setError(<Alert key='login-error' variant='danger' className="mt-4">
        {errorMessage}
      </Alert>);
    }
  }

  return(
    <Card>
      <header>Login</header>
      <section>
        <Form onSubmit={handleSubmission}>
          <Row className="mb-3">
            <Form.Group className="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" value={username} onChange={e => setUsername(e.target.value)} required />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter password" value={password} onChange={e => setPassword(e.target.value)} required />
            </Form.Group>
          </Row>
          <Row>
            <Button variant="primary" type="submit">
              Login
            </Button>
            <Button variant="secondary" type="reset" onClick={e => {setUsername(''); setPassword('')}}>
              Cancel
            </Button>
          </Row>
          <Row>{error}</Row>
        </Form>
      </section>
    </Card>
  )
}

