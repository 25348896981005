import React, { useRef, useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import RecognitionsList from "../components/recognitionsList/RecognitionsList";
import RecognitionListForm from "../components/recognitionsList/recognitionListForm";
import Card from "../components/card/Card";

export default function RecognitionsListPage({token, username}) {
  let now = new Date();
  const [month, setMonth] = useState({ month: now.getMonth() + 1, year: now.getFullYear() });

  return (
    <div className="recognitions-page">
      <Row>
        <Col>
          <Row>
            <RecognitionListForm month={month} setMonth={setMonth}/>
          </Row>
          <Row>
            <RecognitionsList token={token} username={username} month={month} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
